import {handleXhrError} from "@/composables/errorHandling";
import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import {getCurrentUserId} from "@/composables/vuex";
import {httpGet, httpPatch, httpPost, httpPut} from "@/composables/xhr";
import type {AxiosResponse} from "axios";
import type {
  APIRole,
  BasicUser,
  GetUserOptions,
  IdentityVerificationStatusRequest,
  IdentityVerificationStatusResponse,
  ListUser,
  PhoneNumber,
  SimpleUserInfo,
  UpdateUser,
  UserResults,
  VerificationStatus,
  VerificationStatusParams,
} from "pg-isomorphic/api/user";
import type {VeriffFullAutoWebhook, VeriffSessionDetail} from "pg-isomorphic/api/veriffidentity";

export async function getUsers(params?: GetUserOptions): Promise<ListUser[]> {
  try {
    const response = await httpPost<UserResults>("/api/users/query", params || {getAllUsers: true});
    return response.data.users;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_USERS, `Could not retrieve users`, e);
  }
}
export async function getUserById(userId: string): Promise<SimpleUserInfo> {
  try {
    const response = await httpGet<SimpleUserInfo>(`/api/users/${userId}`);
    return response.data as SimpleUserInfo;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_USERS, `Could not retrieve user ${userId}`, e);
  }
}
export async function getConnectionUser(userId: string): Promise<SimpleUserInfo> {
  try {
    const response = await httpGet<SimpleUserInfo>(`/api/users/connection_user/${userId}`);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_CONNECTION_USER, `Could not retrieve user`, e);
  }
}
export async function getCounterPartyUsers(connectionId: string, params?: GetUserOptions): Promise<ListUser[]> {
  try {
    const response = await httpGet<UserResults>(
      `/api/users/counterparty_users/${connectionId}`,
      params || {getAllUsers: true},
    );
    return response.data.users;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_COUNTERPARTY_USERS, `Could not retrieve counterparty users`, e);
  }
}

export async function inviteUser(
  name: string,
  email: string,
  phone?: PhoneNumber,
  fax?: PhoneNumber,
  title?: string,
  country?: string,
  activeConnectionRole?: string,
  roles?: string[],
): Promise<BasicUser> {
  try {
    const response = await httpPost(`/api/admin/users/invite`, {
      name,
      email,
      phone,
      fax,
      title,
      country,
      activeConnectionRole,
      roles,
    });
    return response.data as BasicUser;
  } catch (e) {
    handleXhrError(RegisteredErrors.INVITE_USER, "Could not invite user", e);
  }
}

export async function addNobodyUser(
  name: string,
  email: string,
  title?: string,
  phone?: PhoneNumber,
  fax?: PhoneNumber,
  country?: string,
  activeConnectionRole?: string,
  roles?: string[],
  nonNetworkEntityId?: string,
): Promise<BasicUser> {
  try {
    const response = await httpPost(`/api/users/add_nobody_user`, {
      name,
      email,
      title,
      phone,
      fax,
      country,
      activeConnectionRole,
      roles,
      nonNetworkEntityId,
    });
    return response.data as BasicUser;
  } catch (e) {
    handleXhrError(RegisteredErrors.INVITE_USER, "Could not add user", e);
  }
}

export async function updateUser(id: string, update: UpdateUser): Promise<BasicUser> {
  try {
    const response = await httpPut(`/api/admin/users/${id}`, update);
    return response.data as BasicUser;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not update user ${id}`, e);
  }
}

export async function updateContact(id: string, update: UpdateUser, isEditMode: boolean): Promise<BasicUser> {
  try {
    let response;
    if (isEditMode) {
      // If we're in edit mode then it either means that they are updating themselves or they are a non graphite users
      // and anyone can update themselves or non graphite users
      if (getCurrentUserId() === id) {
        response = await httpPatch(`/api/users/current`, update);
      } else {
        response = await httpPatch(`/api/users/update_nobody_user/${id}`, update);
      }
    } else {
      response = await httpPut(`/api/admin/users/contacts/${id}`, update);
    }
    return response.data as BasicUser;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not update user ${id}`, e);
  }
}

export async function startVeriffSession(): Promise<VeriffSessionDetail> {
  try {
    const response = await httpPost<VeriffSessionDetail>(`/api/veriff-identity/create-session`);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not start Veriff session`, e);
  }
}

export async function fireTestVeriffFullAutoWebhook(event: VeriffFullAutoWebhook): Promise<void> {
  try {
    const response = await httpPost<void>(`/api/veriff-identity/fire-test-veriff-full-auto-webhook`, event);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not start Veriff session`, e);
  }
}

export async function getIdentityVerificationStatus(
  params: IdentityVerificationStatusRequest,
): Promise<IdentityVerificationStatusResponse> {
  try {
    const response = await httpPost<IdentityVerificationStatusResponse>("/api/identity-verification/status", params);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.UPDATE_USER, `Could not find which identity verification services are enabled`, e);
  }
}

export async function getRoles(): Promise<APIRole[]> {
  try {
    const response: AxiosResponse<{roles: APIRole[]}> = await httpGet("/api/users/roles");
    return response.data.roles;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_USER_ROLES, `Could not get user roles`, e);
  }
}

export async function getVerificationStatus(params: VerificationStatusParams): Promise<VerificationStatus[]> {
  try {
    const response = await httpPost<VerificationStatus[]>("/api/users/verification-status-query", params);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_USERS, `Could not get user verifications`, e);
  }
}
