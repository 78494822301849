import {config, library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
// LIGHT ICONS
import {faAddressBook} from "@fortawesome/pro-light-svg-icons/faAddressBook";
import {faAlarmExclamation} from "@fortawesome/pro-light-svg-icons/faAlarmExclamation";
import {faAngleLeft} from "@fortawesome/pro-light-svg-icons/faAngleLeft";
import {faAngleRight} from "@fortawesome/pro-light-svg-icons/faAngleRight";
import {faAngleUp} from "@fortawesome/pro-light-svg-icons/faAngleUp";
import {faArrowCircleLeft} from "@fortawesome/pro-light-svg-icons/faArrowCircleLeft";
import {faArrowCircleRight} from "@fortawesome/pro-light-svg-icons/faArrowCircleRight";
import {faFileArrowUp as falFileArrowUp} from "@fortawesome/pro-light-svg-icons/faFileArrowUp";
import {faArrowLeft as falArrowLeft} from "@fortawesome/pro-light-svg-icons/faArrowLeft";
import {faArrowRight as falArrowRight} from "@fortawesome/pro-light-svg-icons/faArrowRight";
import {faBars} from "@fortawesome/pro-light-svg-icons/faBars";
import {faBell} from "@fortawesome/pro-light-svg-icons/faBell";
import {faBoxOpen} from "@fortawesome/pro-light-svg-icons/faBoxOpen";
import {faBriefcase} from "@fortawesome/pro-light-svg-icons/faBriefcase";
import {faCalendarAlt} from "@fortawesome/pro-light-svg-icons/faCalendarAlt";
import {faCaretRight} from "@fortawesome/pro-light-svg-icons/faCaretRight";
import {faCheck as falCheck} from "@fortawesome/pro-light-svg-icons/faCheck";
import {faLineColumns as falLineColumns} from "@fortawesome/pro-light-svg-icons/faLineColumns";
import {faList as falList} from "@fortawesome/pro-light-svg-icons/faList";
import {faChevronDoubleDown} from "@fortawesome/pro-light-svg-icons/faChevronDoubleDown";
import {faChevronDoubleLeft} from "@fortawesome/pro-light-svg-icons/faChevronDoubleLeft";
import {faChevronDoubleUp} from "@fortawesome/pro-light-svg-icons/faChevronDoubleUp";
import {faChevronDown} from "@fortawesome/pro-light-svg-icons/faChevronDown";
import {faChevronLeft as falChevronLeft} from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import {faChevronRight as falChevronRight} from "@fortawesome/pro-light-svg-icons/faChevronRight";
import {faArrowFromLeft as falArrowFromLeft} from "@fortawesome/pro-light-svg-icons/faArrowFromLeft";
import {faChevronUp} from "@fortawesome/pro-light-svg-icons/faChevronUp";
import {faCircle} from "@fortawesome/pro-light-svg-icons/faCircle";
import {faCircleCheck as falCheckCircle} from "@fortawesome/pro-light-svg-icons/faCircleCheck";
import {faClock} from "@fortawesome/pro-light-svg-icons/faClock";
import {faDesktop} from "@fortawesome/pro-light-svg-icons/faDesktop";
import {faEdit} from "@fortawesome/pro-light-svg-icons/faEdit";
import {faEllipsisH} from "@fortawesome/pro-light-svg-icons/faEllipsisH";
import {faEnvelope} from "@fortawesome/pro-light-svg-icons/faEnvelope";
import {faEnvelopeOpenText} from "@fortawesome/pro-light-svg-icons/faEnvelopeOpenText";
import {faExclamation} from "@fortawesome/pro-light-svg-icons/faExclamation";
import {faExclamationTriangle} from "@fortawesome/pro-light-svg-icons/faExclamationTriangle";
import {faExternalLink} from "@fortawesome/pro-light-svg-icons/faExternalLink";
import {faEye as falEye} from "@fortawesome/pro-light-svg-icons/faEye";
import {faFile} from "@fortawesome/pro-light-svg-icons/faFile";
import {faFileAlt} from "@fortawesome/pro-light-svg-icons/faFileAlt";
import {faFileContract as falFileContract} from "@fortawesome/pro-light-svg-icons/faFileContract";
import {faFileImage} from "@fortawesome/pro-light-svg-icons/faFileImage";
import {faFileSignature} from "@fortawesome/pro-light-svg-icons/faFileSignature";
import {faFilter} from "@fortawesome/pro-light-svg-icons/faFilter";
import {faHandPaper} from "@fortawesome/pro-light-svg-icons/faHandPaper";
import {faHandshake} from "@fortawesome/pro-light-svg-icons/faHandshake";
import {faHistory} from "@fortawesome/pro-light-svg-icons/faHistory";
import {faInfoCircle} from "@fortawesome/pro-light-svg-icons/faInfoCircle";
import {faKey} from "@fortawesome/pro-light-svg-icons/faKey";
import {faLeaf} from "@fortawesome/pro-light-svg-icons/faLeaf";
import {faLink} from "@fortawesome/pro-light-svg-icons/faLink";
import {faLocationArrow} from "@fortawesome/pro-light-svg-icons/faLocationArrow";
import {faLock} from "@fortawesome/pro-light-svg-icons/faLock";
import {faMapMarkedAlt} from "@fortawesome/pro-light-svg-icons/faMapMarkedAlt";
import {faMapMarker} from "@fortawesome/pro-light-svg-icons/faMapMarker";
import {faMapMarkerAlt} from "@fortawesome/pro-light-svg-icons/faMapMarkerAlt";
import {faMinus} from "@fortawesome/pro-light-svg-icons/faMinus";
import {faPencilAlt} from "@fortawesome/pro-light-svg-icons/faPencilAlt";
import {faPencilSlash} from "@fortawesome/pro-light-svg-icons/faPencilSlash";
import {faPlug} from "@fortawesome/pro-light-svg-icons/faPlug";
import {faPlus} from "@fortawesome/pro-light-svg-icons/faPlus";
import {faPlusCircle} from "@fortawesome/pro-light-svg-icons/faPlusCircle";
import {faProjectDiagram} from "@fortawesome/pro-light-svg-icons/faProjectDiagram";
import {faSave} from "@fortawesome/pro-light-svg-icons/faSave";
import {faSearch as falSearch} from "@fortawesome/pro-light-svg-icons/faSearch";
import {faSearch as fasSearch} from "@fortawesome/pro-solid-svg-icons/faSearch";
import {faShareAlt as falShareAlt} from "@fortawesome/pro-light-svg-icons/faShareAlt";
import {faShieldAlt} from "@fortawesome/pro-light-svg-icons/faShieldAlt";
import {faSignInAlt as falSignInAlt} from "@fortawesome/pro-light-svg-icons/faSignInAlt";
import {faSkullCrossbones as falSkullCrossbones} from "@fortawesome/pro-light-svg-icons/faSkullCrossbones";
import {faStickyNote} from "@fortawesome/pro-light-svg-icons/faStickyNote";
import {faSuitcase} from "@fortawesome/pro-light-svg-icons/faSuitcase";
import {faTag} from "@fortawesome/pro-light-svg-icons/faTag";
import {faTasks} from "@fortawesome/pro-light-svg-icons/faTasks";
import {faThLarge} from "@fortawesome/pro-light-svg-icons/faThLarge";
import {faThList} from "@fortawesome/pro-light-svg-icons";
import {faTimes} from "@fortawesome/pro-light-svg-icons/faTimes";
import {faTimesCircle} from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import {faTrash as falTrash, faTrash} from "@fortawesome/pro-light-svg-icons/faTrash";
import {faUndo} from "@fortawesome/pro-light-svg-icons/faUndo";
import {faUnlock} from "@fortawesome/pro-light-svg-icons/faUnlock";
import {faUser} from "@fortawesome/pro-light-svg-icons/faUser";
import {faUserEdit} from "@fortawesome/pro-light-svg-icons/faUserEdit";
import {faUserFriends} from "@fortawesome/pro-light-svg-icons/faUserFriends";
import {faWindowMaximize as falWindowMaximize} from "@fortawesome/pro-light-svg-icons/faWindowMaximize";
import {faAngleDown} from "@fortawesome/pro-light-svg-icons/faAngleDown";
import {faTasksAlt} from "@fortawesome/pro-light-svg-icons/faTasksAlt";
import {faGlobe} from "@fortawesome/pro-light-svg-icons/faGlobe";
import {faClipboardListCheck} from "@fortawesome/pro-light-svg-icons/faClipboardListCheck";
import {faBallotCheck} from "@fortawesome/pro-light-svg-icons/faBallotCheck";
import {faUserSlash} from "@fortawesome/pro-light-svg-icons/faUserSlash";
import {faFileExport} from "@fortawesome/pro-light-svg-icons/faFileExport";
import {faPollH as falPollH} from "@fortawesome/pro-light-svg-icons/faPollH";
import {faDumpsterFire as falDumpsterFire} from "@fortawesome/pro-light-svg-icons/faDumpsterFire";
import {faHome as falHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faTasks as falTasks} from "@fortawesome/pro-light-svg-icons/faTasks";
import {faNetworkWired as falNetworkWired} from "@fortawesome/pro-light-svg-icons/faNetworkWired";
import {faFolderOpen as falFolderOpen} from "@fortawesome/pro-light-svg-icons/faFolderOpen";
import {faClipboardCheck as falClipboardCheck} from "@fortawesome/pro-light-svg-icons/faClipboardCheck";
import {faLightbulbOn as falLightbulbOn} from "@fortawesome/pro-light-svg-icons/faLightbulbOn";
import {faMapMarkerExclamation as falMapMarkerExclamation} from "@fortawesome/pro-light-svg-icons/faMapMarkerExclamation";
import {faFileChartLine as falFileChartLine} from "@fortawesome/pro-light-svg-icons/faFileChartLine";
import {faCommentAlt as falCommentAlt} from "@fortawesome/pro-light-svg-icons/faCommentAlt";
import {faBuilding as falBuilding} from "@fortawesome/pro-light-svg-icons/faBuilding";

// REGULAR ICONS
import {faAlarmClock as farAlarmClock} from "@fortawesome/pro-regular-svg-icons/faAlarmClock";
import {faAlignLeft as farAlignLeft} from "@fortawesome/pro-regular-svg-icons/faAlignLeft";
import {faAngleUp as farAngleUp} from "@fortawesome/pro-regular-svg-icons/faAngleUp";
import {faAngleDown as farAngleDown} from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import {faArrowRotateRight as farArrowRotateRight} from "@fortawesome/pro-regular-svg-icons/faArrowRotateRight";
import {faBallot} from "@fortawesome/pro-regular-svg-icons/faBallot";
import {faBan} from "@fortawesome/pro-regular-svg-icons/faBan";
import {faBox} from "@fortawesome/pro-regular-svg-icons/faBox";
import {faBuilding} from "@fortawesome/pro-regular-svg-icons/faBuilding";
import {faBuildingCircleArrowRight} from "@fortawesome/pro-regular-svg-icons/faBuildingCircleArrowRight";
import {faCaretSquareUp as farCaretSquareUp} from "@fortawesome/pro-regular-svg-icons/faCaretSquareUp";
import {faCode} from "@fortawesome/pro-regular-svg-icons/faCode";
import {faCheckCircle as farCheckCircle} from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import {faChevronRight as farChevronRight} from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import {faClipboardCheck as farClipboardCheck} from "@fortawesome/pro-regular-svg-icons/faClipboardCheck";
import {faClipboardList as farClipboardList} from "@fortawesome/pro-regular-svg-icons/faClipboardList";
import {faClock as farClock} from "@fortawesome/pro-regular-svg-icons/faClock";
import {faCodeBranch as farCodeBranch} from "@fortawesome/pro-regular-svg-icons/faCodeBranch";
import {faCommentAlt as farCommentAlt} from "@fortawesome/pro-regular-svg-icons/faCommentAlt";
import {faCompress} from "@fortawesome/pro-regular-svg-icons/faCompress";
import {faCopy} from "@fortawesome/pro-regular-svg-icons/faCopy";
import {faCubes} from "@fortawesome/pro-regular-svg-icons/faCubes";
import {faEllipsisV} from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import {faEnvelope as farEnvelope} from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import {faEnvelopeOpenText as farEnvelopeOpenText} from "@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText";
import {faExpand} from "@fortawesome/pro-regular-svg-icons/faExpand";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faEyeSlash as farEyeSlash} from "@fortawesome/pro-regular-svg-icons/faEyeSlash";
import {faFax as farFax} from "@fortawesome/pro-regular-svg-icons/faFax";
import {faFileContract as farFileContract} from "@fortawesome/pro-regular-svg-icons/faFileContract";
import {faFileDownload as farFileDownload} from "@fortawesome/pro-regular-svg-icons/faFileDownload";
import {faFileUpload as farFileUpload} from "@fortawesome/pro-regular-svg-icons/faFileUpload";
import {faFileArrowUp} from "@fortawesome/pro-regular-svg-icons/faFileArrowUp";
import {faFileSignature as farFileSignature} from "@fortawesome/pro-regular-svg-icons/faFileSignature";
import {faGlobeAmericas} from "@fortawesome/pro-regular-svg-icons/faGlobeAmericas";
import {faHandHoldingBox as farHandHoldingBox} from "@fortawesome/pro-regular-svg-icons/faHandHoldingBox";
import {faHandshake as farHandshake} from "@fortawesome/pro-regular-svg-icons/faHandshake";
import {faInfoCircle as farInfoCircle} from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import {faLayerGroup} from "@fortawesome/pro-regular-svg-icons/faLayerGroup";
import {faList} from "@fortawesome/pro-regular-svg-icons/faList";
import {faLock as farLock} from "@fortawesome/pro-regular-svg-icons/faLock";
import {faMagnifyingGlassMinus as farMagnifyingGlassMinus} from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassMinus";
import {faMessageExclamation as farMessageExclamation} from "@fortawesome/pro-regular-svg-icons/faMessageExclamation";
import {faPencil as farPencil} from "@fortawesome/pro-regular-svg-icons/faPencil";
import {faPhone as farPhone} from "@fortawesome/pro-regular-svg-icons/faPhone";
import {faPollH as farPollH} from "@fortawesome/pro-regular-svg-icons/faPollH";
import {faSquare, faSquare as farSquare} from "@fortawesome/pro-regular-svg-icons/faSquare";
import {faStickyNote as farStickyNote} from "@fortawesome/pro-regular-svg-icons/faStickyNote";
import {faThumbsDown} from "@fortawesome/pro-regular-svg-icons/faThumbsDown";
import {faThumbsUp} from "@fortawesome/pro-regular-svg-icons/faThumbsUp";
import {faTimes as farTimes} from "@fortawesome/pro-regular-svg-icons/faTimes";
import {faTrash as farTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {faUpload} from "@fortawesome/pro-regular-svg-icons/faUpload";
import {faDownload} from "@fortawesome/pro-regular-svg-icons/faDownload";
import {faLanguage} from "@fortawesome/pro-regular-svg-icons/faLanguage";
import {faWindowMaximize} from "@fortawesome/pro-regular-svg-icons/faWindowMaximize";
import {faWindowMinimize} from "@fortawesome/pro-regular-svg-icons/faWindowMinimize";
import {faSave as farSave} from "@fortawesome/pro-regular-svg-icons/faSave";
import {faPaperPlane as farPaperPlane} from "@fortawesome/pro-regular-svg-icons/faPaperPlane";
import {faExternalLink as farExternalLink} from "@fortawesome/pro-regular-svg-icons/faExternalLink";
import {faPaperclip as farPaperclip} from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import {faAt as farAt} from "@fortawesome/pro-regular-svg-icons/faAt";
import {faHome as farHome} from "@fortawesome/pro-regular-svg-icons/faHome";
import {faFileExcel as farFileExcel} from "@fortawesome/pro-regular-svg-icons/faFileExcel";
import {faExclamationTriangle as farExclamationTriangle} from "@fortawesome/pro-regular-svg-icons/faExclamationTriangle";
import {faUser as farUser} from "@fortawesome/pro-regular-svg-icons/faUser";
import {faUsers as farUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";
import {faStream as farStream} from "@fortawesome/pro-regular-svg-icons/faStream";
import {faQuestionCircle as farQuestionCircle} from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import {faLink as farLink} from "@fortawesome/pro-regular-svg-icons/faLink";
import {faTag as farTag} from "@fortawesome/pro-regular-svg-icons/faTag";
import {faBoxOpen as farBoxOpen} from "@fortawesome/pro-regular-svg-icons/faBoxOpen";
import {faLightbulb} from "@fortawesome/pro-regular-svg-icons/faLightbulb";
import {faArrows as farArrows} from "@fortawesome/pro-regular-svg-icons/faArrows";
import {faTrophyAlt as farTrophyAlt} from "@fortawesome/pro-regular-svg-icons/faTrophyAlt";
import {faUnlink as farUnlink} from "@fortawesome/pro-regular-svg-icons/faUnlink";
import {faBookmark as farBookmark} from "@fortawesome/pro-regular-svg-icons/faBookmark";
import {faStopwatch as farStopwatch} from "@fortawesome/pro-regular-svg-icons/faStopwatch";

// SOLID ICONS
import {faCheckSquare as fasCheckSquare} from "@fortawesome/pro-solid-svg-icons/faCheckSquare";
import {faAngleRight as fasAngleRight} from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import {faArrowAltDown} from "@fortawesome/pro-solid-svg-icons/faArrowAltDown";
import {faArrowAltRight} from "@fortawesome/pro-solid-svg-icons/faArrowAltRight";
import {faArrowAltUp} from "@fortawesome/pro-solid-svg-icons/faArrowAltUp";
import {faArrowsToLine} from "@fortawesome/pro-solid-svg-icons/faArrowsToLine";
import {faAsterisk} from "@fortawesome/pro-solid-svg-icons/faAsterisk";
import {faCaretLeft as fasCaretLeft} from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import {faCaretRight as fasCaretRight} from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import {faCaretDown as fasCaretDown} from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import {faCaretSquareUp as fasCaretSquareUp} from "@fortawesome/pro-solid-svg-icons/faCaretSquareUp";
import {faChartNetwork} from "@fortawesome/pro-solid-svg-icons/faChartNetwork";
import {faCheck} from "@fortawesome/pro-solid-svg-icons/faCheck";
import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import {faChevronDown as fasChevronDown} from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import {faChevronCircleLeft} from "@fortawesome/pro-solid-svg-icons/faChevronCircleLeft";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import {faChevronCircleRight} from "@fortawesome/pro-solid-svg-icons/faChevronCircleRight";
import {faChevronUp as fasChevronUp} from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import {faCircleXmark as fasCircleXmark} from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import {faUserCircle} from "@fortawesome/pro-solid-svg-icons/faUserCircle";
import {faClipboardCheck as fasClipboardCheck} from "@fortawesome/pro-solid-svg-icons/faClipboardCheck";
import {faClipboardListCheck as fasClipboardListCheck} from "@fortawesome/pro-solid-svg-icons/faClipboardListCheck";
import {faClock as fasClock} from "@fortawesome/pro-solid-svg-icons/faClock";
import {faCommentAlt as fasCommentAlt} from "@fortawesome/pro-solid-svg-icons/faCommentAlt";
import {faEllipsisH as fasEllipsisH} from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import {faFileExcel} from "@fortawesome/pro-solid-svg-icons/faFileExcel";
import {faExclamation as fasExclamation} from "@fortawesome/pro-solid-svg-icons/faExclamation";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import {faExclamationCircle as falExclamationCircle} from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import {faExclamationTriangle as fasExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {faExternalLinkAlt} from "@fortawesome/pro-solid-svg-icons/faExternalLinkAlt";
import {faFile as fasFile} from "@fortawesome/pro-solid-svg-icons/faFile";
import {faFilter as fasFilter} from "@fortawesome/pro-solid-svg-icons/faFilter";
import {faHandsWash as fasHandsWash} from "@fortawesome/pro-solid-svg-icons/faHandsWash";
import {faFolderOpen as fasFolderOpen} from "@fortawesome/pro-solid-svg-icons/faFolderOpen";
import {faLightbulbOn} from "@fortawesome/pro-solid-svg-icons/faLightbulbOn";
import {faLineColumns as fasLineColumns} from "@fortawesome/pro-solid-svg-icons/faLineColumns";
import {faList as fasList} from "@fortawesome/pro-solid-svg-icons/faList";
import {faMinus as fasMinus} from "@fortawesome/pro-solid-svg-icons/faMinus";
import {faSave as fasSave} from "@fortawesome/pro-solid-svg-icons/faSave";
import {faSitemap} from "@fortawesome/pro-solid-svg-icons/faSitemap";
import {faSort} from "@fortawesome/pro-solid-svg-icons/faSort";
import {faSortDown} from "@fortawesome/pro-solid-svg-icons/faSortDown";
import {faBadgeCheck as faBadgeCheck} from "@fortawesome/pro-solid-svg-icons/faBadgeCheck";
import {faSortUp} from "@fortawesome/pro-solid-svg-icons/faSortUp";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons/faSpinner";
import {faStickyNote as fasStickyNote} from "@fortawesome/pro-solid-svg-icons/faStickyNote";
import {faSync as fasSync} from "@fortawesome/pro-solid-svg-icons/faSync";
import {faThumbsDown as faThumbsDownSolid} from "@fortawesome/pro-solid-svg-icons/faThumbsDown";
import {faThumbsUp as faThumbsUpSolid} from "@fortawesome/pro-solid-svg-icons/faThumbsUp";
import {faTimesCircle as fasTimesCircle} from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import {faTrash as fasTrash} from "@fortawesome/pro-solid-svg-icons/faTrash";
import {faShareSquare} from "@fortawesome/pro-solid-svg-icons/faShareSquare";
import {faShare} from "@fortawesome/pro-solid-svg-icons/faShare";
import {faInfoCircle as fasInfoCircle} from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import {faRedo as fasRedo} from "@fortawesome/pro-solid-svg-icons/faRedo";
import {faGripVertical as fasGripVertical} from "@fortawesome/pro-solid-svg-icons/faGripVertical";
import {faArrowLeft as fasArrowLeft} from "@fortawesome/pro-solid-svg-icons/faArrowLeft";
import {faPencil as fasPencil} from "@fortawesome/pro-solid-svg-icons/faPencil";
import {faHistory as fasHistory} from "@fortawesome/pro-solid-svg-icons/faHistory";
import {faLock as fasLock} from "@fortawesome/pro-solid-svg-icons/faLock";
import {faUnlock as fasUnlock} from "@fortawesome/pro-solid-svg-icons/faUnlock";
import {faStar as fasStar} from "@fortawesome/pro-solid-svg-icons/faStar";
import {faMoneyCheckAlt} from "@fortawesome/pro-solid-svg-icons/faMoneyCheckAlt";
import {faFileInvoiceDollar} from "@fortawesome/pro-solid-svg-icons/faFileInvoiceDollar";
import {faBalanceScale} from "@fortawesome/pro-solid-svg-icons/faBalanceScale";
import {faPersonDolly} from "@fortawesome/pro-solid-svg-icons/faPersonDolly";
import {faEye as fasEye} from "@fortawesome/pro-solid-svg-icons/faEye";
import {faAngleDown as fasAngleDown} from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import {faAngleUp as fasAngleUp} from "@fortawesome/pro-solid-svg-icons/faAngleUp";
import {faHammerWar as fasHammerWar} from "@fortawesome/pro-solid-svg-icons/faHammerWar";
import {faServer as fasServer} from "@fortawesome/pro-solid-svg-icons/faServer";
import {faXmark as fasXmark} from "@fortawesome/pro-solid-svg-icons/faXmark";
import {faCommentTimes as fasCommentTimes} from "@fortawesome/pro-solid-svg-icons/faCommentTimes";
import {faHourglassStart as fasHourglassStart} from "@fortawesome/pro-solid-svg-icons/faHourglassStart";

export function setupFontAwesome() {
  config.autoAddCss = false;
  library.add(
    faAddressBook,
    faAlarmExclamation,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    farArrows,
    faArrowAltDown,
    faArrowAltRight,
    faArrowAltUp,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowsToLine,
    falArrowLeft,
    faAsterisk,
    faBalanceScale,
    faBallot,
    faBallotCheck,
    faBan,
    faBox,
    faBars,
    faBell,
    faBoxOpen,
    faBriefcase,
    faBuilding,
    faBuildingCircleArrowRight,
    faCalendarAlt,
    faCaretRight,
    faChartNetwork,
    faCheck,
    faCheckCircle,
    faChevronCircleLeft,
    faChevronCircleRight,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faClipboardListCheck,
    faClock,
    faCode,
    falHome,
    falTasks,
    falNetworkWired,
    falFolderOpen,
    falClipboardCheck,
    falLightbulbOn,
    faLightbulb,
    falMapMarkerExclamation,
    falFileChartLine,
    falCommentAlt,
    faCompress,
    faCopy,
    faCubes,
    faDesktop,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faEnvelopeOpenText,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExternalLink,
    faExternalLinkAlt,
    faEye,
    faFile,
    faFileAlt,
    faFileArrowUp,
    falFileArrowUp,
    faFileExcel,
    falFileContract,
    faFileExport,
    faFileImage,
    faFileInvoiceDollar,
    fasGripVertical,
    faFileSignature,
    faFilter,
    faGlobe,
    faGlobeAmericas,
    faHandPaper,
    faHandshake,
    faHandshake,
    faHistory,
    faInfoCircle,
    faInfoCircle,
    faKey,
    faLanguage,
    faLayerGroup,
    faLeaf,
    faLightbulbOn,
    faLink,
    faList,
    faLocationArrow,
    faLock,
    faMapMarkedAlt,
    faMapMarker,
    faMapMarkerAlt,
    faMinus,
    fasMinus,
    faMoneyCheckAlt,
    faPencilAlt,
    faPencilSlash,
    faPersonDolly,
    faPlug,
    faPlus,
    faPlusCircle,
    faProjectDiagram,
    faSave,
    faShare,
    faShareSquare,
    faShieldAlt,
    faSitemap,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faBadgeCheck,
    faSquare,
    faStickyNote,
    faSuitcase,
    faTag,
    faTasks,
    faTasksAlt,
    faThLarge,
    faThList,
    faThumbsDown,
    faThumbsDownSolid,
    faThumbsUp,
    faThumbsUpSolid,
    faTimes,
    faTimes,
    faTimesCircle,
    faTrash,
    faUndo,
    faUnlock,
    faUpload,
    faUser,
    faUserCircle,
    faUserEdit,
    faUserFriends,
    faUserSlash,
    faWindowMaximize,
    faWindowMinimize,
    falCheck,
    falCheckCircle,
    farUnlink,
    falChevronLeft,
    falChevronRight,
    falArrowFromLeft,
    falExclamationCircle,
    falEye,
    falFolderOpen,
    falLightbulbOn,
    falLineColumns,
    falList,
    falPollH,
    falSearch,
    falShareAlt,
    falSignInAlt,
    falSkullCrossbones,
    falTrash,
    falWindowMaximize,
    farAlarmClock,
    farAlignLeft,
    farAngleUp,
    farAngleDown,
    farArrowRotateRight,
    farAt,
    farCaretSquareUp,
    farCheckCircle,
    farChevronRight,
    farClipboardCheck,
    farClipboardList,
    farClock,
    farCodeBranch,
    farCommentAlt,
    farEnvelope,
    farEnvelopeOpenText,
    farExclamationTriangle,
    farExternalLink,
    farEyeSlash,
    farFax,
    farFileContract,
    farFileDownload,
    farFileExcel,
    farFileSignature,
    farFileUpload,
    farHandHoldingBox,
    farHandshake,
    farInfoCircle,
    farHome,
    farLock,
    farMagnifyingGlassMinus,
    farMessageExclamation,
    farPaperPlane,
    farPaperclip,
    farPencil,
    farPhone,
    farPollH,
    farSave,
    farSquare,
    farStickyNote,
    farTimes,
    farTrash,
    fasAngleDown,
    fasAngleUp,
    falDumpsterFire,
    fasAngleRight,
    fasArrowLeft,
    fasCaretDown,
    fasCaretLeft,
    fasCaretRight,
    fasCaretSquareUp,
    fasCheckSquare,
    fasChevronDown,
    fasChevronUp,
    fasCircleXmark,
    fasClipboardCheck,
    fasClipboardListCheck,
    fasClock,
    fasCommentAlt,
    fasEllipsisH,
    fasExclamation,
    fasExclamationTriangle,
    fasEye,
    fasFile,
    fasFilter,
    fasFolderOpen,
    fasHandsWash,
    fasHistory,
    fasInfoCircle,
    fasLineColumns,
    fasList,
    fasLock,
    fasPencil,
    fasRedo,
    fasSave,
    fasSearch,
    farUser,
    farUsers,
    farStream,
    farQuestionCircle,
    farLink,
    falArrowRight,
    farTag,
    farBoxOpen,
    fasStar,
    fasStickyNote,
    fasSync,
    fasTimesCircle,
    fasTrash,
    fasUnlock,
    fasHammerWar,
    fasServer,
    fasXmark,
    fasCommentTimes,
    farTrophyAlt,
    farBookmark,
    falBuilding,
    fasHourglassStart,
    farStopwatch,
  );

  return FontAwesomeIcon;
}
